import { Page404 } from "design-system/components/pages/page-404/page-404";
import { createSiteHeaderProps } from "frontend/contentful/components/universal/site-header.props";
import { createSiteFooterProps } from "frontend/contentful/components/universal/site-footer.props";
import { Link } from "design-system/components/primitives/link/link";
import { useContentful } from "frontend/hooks/use-contentful";
import { ContentfulRichText } from "../blocks/rich-text.contentful";
import { usePageProps } from "frontend/hooks/use-page-props";
import { ContentfulHead } from "../primitives/head.contentful";

// assumes it is rapped in the PagePropsProvider
export function Error404Page() {
  // error404Settings is separated out into pageProps due to supporting a root site fallback
  const { error404Settings } = usePageProps();
  const { site, getEntry, getEntries } = useContentful();

  const header = site ? createSiteHeaderProps(site) : undefined;
  const footer = site ? createSiteFooterProps(site.fields.footer) : undefined;
  const settings = getEntry(error404Settings);
  const body = getEntries(settings?.fields.body);

  return (
    <>
      <ContentfulHead title="Page not found | Harvard Business School" />

      <Page404
        theme={"light"}
        header={{ name: "SiteHeader", props: header }}
        footer={footer}
        title={settings?.fields.title || "Oops! Something went wrong."}
      >
        {body ? (
          body.map((entry, i) => <ContentfulRichText key={i} entry={entry} />)
        ) : (
          <p>
            The page you&rsquo;re looking for was not found.{" "}
            <Link href="/sitemap">Try the sitemap</Link> instead.
          </p>
        )}
      </Page404>
    </>
  );
}
