import "./page-404.scss";

import {
  BaseLayout,
  SiteHeaderComponent,
} from "design-system/components/layouts/base";
import { SiteFooterProps } from "design-system/components/universal/site-footer/site-footer";
import { Theme } from "design-system/utils/theme";
import { Link } from "design-system/components/primitives/link/link";
import { ReactNode } from "react";

export interface Page404Props {
  title?: string;
  children?: ReactNode;
  footer?: SiteFooterProps;
  header?: SiteHeaderComponent;
  theme?: Theme;
}

export function Page404({
  title,
  children,
  footer,
  header,
  theme,
}: Page404Props) {
  return (
    <BaseLayout
      className="hbs-page hbs-page-404"
      footer={footer}
      header={header}
      theme={theme}
    >
      <main id="skipto-main" tabIndex={-1}>
        <div className="hbs-topper hbs-topper-wrapper" data-theme={theme}>
          <div className="hbs-page-404__topper">
            <div className="hbs-page-404__topper-content">
              <h1>{title || "Oops! Something went wrong."}</h1>
            </div>
          </div>
        </div>
        <div className="hbs-page-404__content">
          <div className="hbs-page-404__content-inner">
            {children || (
              <p>
                The page you&rsquo;re looking for was not found. Try the{" "}
                <Link href="https://www.hbs.edu/siteindex">sitemap</Link>{" "}
                instead.
              </p>
            )}
          </div>
        </div>
      </main>
    </BaseLayout>
  );
}
